import LiveEvent from "../../components/live/live";

new LiveEvent('click', '[data-self-ajax]', function selfAjax(e) {
    e.preventDefault();
    let link = this.dataset.selfAjax;
    let before_text = this.innerHTML;
    this.innerHTML = '<i class="fas fa-circle-notch fa-spin"></i>';

    fetch(link, { headers: { "Content-Type": "application/json; charset=utf-8" }})
        .then(res => res.json())
        .then(response => {
            this.innerHTML = response.text;
        })
        .catch(err => {
            this.innerHTML = '<li><i class="fas fa-times" style="color: red"></i> '+before_text;
        });
});