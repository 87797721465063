document.querySelectorAll('blockquote').forEach((blockquote) => {
    blockquote.style.display = 'none';
    const elem = document.createElement("a");
    elem.href="#";
    elem.innerHTML="<i class=\"fas fa-quote-right\"></i>";

    blockquote.before(elem);


    elem.addEventListener('click', (e) => {
        e.preventDefault();
        const target = elem.nextSibling;

        if(target.style.display === 'none'){
            target.style.display = 'block';
        }else{
            target.style.display = 'none';
        }
    });
});