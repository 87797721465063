
import LiveEvent from "../../components/live/live";

new LiveEvent('load', '[data-full-iframe]', function (e) {
    let frame = new Iframe_update_height();
    frame.init(this);
    frame.test(this);
});

class Iframe_update_height {



    init(obj){

        console.log('init')

        obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px';
    }

    test(obj){
        window.focus();

        window.addEventListener("blur", () => {
            setTimeout(() => {
                if (document.activeElement.tagName === "IFRAME") {
                    console.log('click');

                    const func = () => {
                        this.init(obj)
                    };
                    setTimeout(func, 100);


                    window.focus();
                }
            });
        });

    }
}

