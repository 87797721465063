require('../scss/app.scss');

window.noZensmooth = true;


require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');

require('./common/self_ajax.js');

require('@fortawesome/fontawesome-free/js/all');


require('./pages/project.js');
require('./task/email.js');


require('./elements/full-iframe');



