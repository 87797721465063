// PasswordForm[text]

import LiveEvent from "../../components/live/live";

new LiveEvent('keyup', '[data-project-textarea]', function () {
    const info = document.querySelector('[data-project-info] ul');
    if(this.value.length>0){
        info.style.display = 'block';
    }else{
        info.style.display = 'none';
    }
});

new LiveEvent('click', '[data-account]', function () {
    const id_account = this.dataset.account;
    const account_type = this.dataset.account_type;
    const content = document.querySelector('[data-account-content="'+id_account+'"]');
    const isLoaded = content.classList.contains('loaded');
    const header_text = this.innerHTML;

    this.innerHTML += '<i class="fas fa-circle-notch fa-spin fa-pull-right"></i>';

    if(isLoaded){
        content.innerHTML = '';
        content.classList.remove('loaded');
        this.innerHTML = header_text;
        return true;
    }

    fetch('/_account/'+id_account, { headers: { "Content-Type": "application/json; charset=utf-8" }})
        .then(res => res.json())
        .then(response => {
            if(response.state==='error'){
                alert("sorry, error");
            }
            if(response.state==='success'){
                content.classList.add('loaded');
                content.innerHTML = '';
                if(response.server) content.innerHTML += '<p class="server"><i class="far fa-copy" data-clipboard="'+response.server+'"></i> '+createTextLinks(response.server)+'</p>';
                if(response.login) content.innerHTML += '<p class="login"><i class="far fa-copy" data-clipboard="'+response.login+'"></i> '+response.login+'</p>';
                if(response.password) content.innerHTML += '<p class="password"><i class="far fa-copy" data-clipboard="'+response.password+'"></i> '+response.password+'</p>';
                if(response.caption) content.innerHTML += '<p class="caption">'+response.caption.replace(/(?:\r\n|\r|\n)/g, '<br>')+'</p>';

                if((account_type==='ssh')||(account_type==='root')){
                    content.innerHTML += '<p class="ssh"><i class="far fa-copy" data-clipboard="ssh '+response.login+'@'+response.server+'"></i> ssh '+response.login+'@'+response.server+'</p>';
                }

                //Только админу
                content.innerHTML += '<p class="everything"><i class="far fa-copy" data-clipboard="'+account_type+'\nСервер: '+response.server+'\nЛогин: '+response.login+'\nПароль: '+response.password+'\n'+response.caption+'"></i></p>';


                setInterval(() => {
                    content.innerHTML = '';
                    content.classList.remove('loaded');
                }, 60000);

                new LiveEvent('click', '[data-clipboard]', function () {
                    this.classList.add('animate-shake');
                    copyToClipboard(this.dataset.clipboard);
                });
            }
            this.innerHTML = header_text;
        })
        .catch(err => {
            alert("Сайт ответил отказом")
        });
});

function createTextLinks(text) {

    return (text || "").replace(
        /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
        function(match, space, url){
            var hyperlink = url;
            if (!hyperlink.match('^https?:\/\/')) {
                hyperlink = 'http://' + hyperlink;
            }
            return space + '<a href="' + hyperlink + '" target="_blank">' + url + '</a>';
        }
    );
};

function copyToClipboard(str) {
    /* ——— Derived from: https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
           improved to add iOS device compatibility——— */
    const el = document.createElement('textarea'); // Create a <textarea> element

    let storeContentEditable = el.contentEditable;
    let storeReadOnly = el.readOnly;

    el.value = str; // Set its value to the string that you want copied
    el.contentEditable = true;
    el.readOnly = false;
    el.setAttribute('readonly', false); // Make it readonly false for iOS compatability
    el.setAttribute('contenteditable', true); // Make it editable for iOS
    el.style.position = 'absolute';
    el.style.left = '-9999px'; // Move outside the screen to make it invisible
    document.body.appendChild(el); // Append the <textarea> element to the HTML document
    const selected =
        document.getSelection().rangeCount > 0 // Check if there is any content selected previously
            ? document.getSelection().getRangeAt(0) // Store selection if found
            : false; // Mark as false to know no selection existed before
    el.select(); // Select the <textarea> content
    el.setSelectionRange(0, 999999);
    document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el); // Remove the <textarea> element
    if (selected) {
        // If a selection existed before copying
        document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
        document.getSelection().addRange(selected); // Restore the original selection
    }

    el.contentEditable = storeContentEditable;
    el.readOnly = storeReadOnly;
}